<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">예약일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  type="lookup-condition"
                  v-model="reservationDateRange"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        v-model="timeTableOption.isWebTimeView"
                      />
                      <i></i>
                      <div class="label">Web</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        v-model="timeTableOption.isAgencyTimeView"
                      />
                      <i></i>
                      <div class="label">Agency</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        v-model="timeTableOption.isSelfTimeView"
                      />
                      <i></i>
                      <div class="label">Self</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        v-model="timeTableOption.isJoinTimeView"
                      />
                      <i></i>
                      <div class="label">Join</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :isShortcutButton="true"
              :ignore="isPopupOpened"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">코스구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  ref="courseDiv"
                  v-model="timeTableOption.courseDiv"
                  :dataSource="timeTableOption.courseDivOptions"
                  :fields="commonCodeFields"
                />
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
            button-div="GET"
            @click.native="searchDetail"
          >
            상세검색
          </erp-button>
        </div>
        <ul class="lookup-button">
          <li class="shortcut">
            <ejs-dropdownbutton
              cssClass="lookup-button-dropdown"
              ref="shortcutMenuDropdownButton"
              :items="shortcutMenuItems"
              @select="shortcutMenuSelected"
            >
              바로가기
            </ejs-dropdownbutton>
          </li>
          <!--
          <li class="etc">
            <ejs-dropdownbutton
              ref='etcMenuDropdownButton'
              cssClass='lookup-button-dropdown'
              :items='etcMenuItems'>
              기타
            </ejs-dropdownbutton>
          </li>
          -->
        </ul>
      </div>
    </div>
    <div class="content-body">
      <article
          class="body-article"
          :class="[
              isReservationDetailViewFixed
                ? $t('className.reservation.reservationListViewFixed')
                : '',
          ]"
      >
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">타임 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
              <ul class="header-label">
                <li class="field">
                  <div class="title">NO</div>
                  <ul class="content">
                    <li class="item">
                      <i
                        v-bind:style="{
                            'background-color': commonCodesGetColorValue(
                              'COLOR_TIME_STATUS',
                              'WEB_OPEN_FLAG'
                            ),
                          }"
                      ></i>
                      <div class="label">웹</div>
                    </li>
                    <li class="item">
                      <i
                        v-bind:style="{
                            'background-color': commonCodesGetColorValue(
                              'COLOR_TIME_STATUS',
                              'WEB_OCLUB_TIME'
                            ),
                          }"
                      ></i>
                      <div class="label">이용권</div>
                    </li>
                    <li class="item">
                      <i
                        v-bind:style="{
                            'background-color': commonCodesGetColorValue(
                              'COLOR_TIME_STATUS',
                              'AGNCY_OPEN_FLAG'
                            ),
                          }"
                      ></i>
                      <div class="label">대행</div>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">타임상태</div>
                  <ul class="content">
                    <li class="item">
                      <i
                        v-bind:style="{
                          'background-color': commonCodesGetColorValue(
                            'TIME_STATUS',
                            'RESVE'
                          ),
                        }"
                      ></i>
                      <div class="label">예약</div>
                    </li>
                    <li class="item">
                      <i
                        v-bind:style="{
                          'background-color': commonCodesGetColorValue(
                            'TIME_STATUS',
                            'BLANK'
                          ),
                        }"
                      ></i>
                      <div class="label">숨김</div>
                    </li>
                    <li class="item">
                      <i
                        v-bind:style="{
                          'background-color': commonCodesGetColorValue(
                            'TIME_STATUS',
                            'BLOCK'
                          ),
                        }"
                      ></i>
                      <div class="label">블럭</div>
                    </li>
                    <li class="item">
                      <i
                        v-bind:style="{
                          'background-color': commonCodesGetColorValue(
                            'TIME_STATUS',
                            'HOLD'
                          ),
                        }"
                      ></i>
                      <div class="label">홀딩</div>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">홀구분</div>
                  <ul class="content">
                    <li class="item">
                      <i
                        v-bind:style="{
                          'background-color': commonCodesGetColorValue(
                            'HOLE_DIV',
                            '9'
                          ),
                        }"
                      ></i>
                      <div class="label">9홀 이하</div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="save keyColor">
                  <erp-button
                      buttonDiv="SAVE"
                      :is-shortcut-button="true"
                      :isIconCustom="true"
                      :ignore="isPopupOpened"
                      @click.native="onSaveButtonClicked"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      buttonDiv="DELETE"
                      :is-shortcut-button="true"
                      :isIconCustom="true"
                      :disabled="isModifyMode"
                      :ignore="isPopupOpened"
                      @click.native="onDeleteButtonClicked"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="reset">
                  <erp-button
                      buttonDiv="GET"
                      :isIconCustom="true"
                      @click.native="onTimeTableInitClicked"
                  >
                    초기화
                  </erp-button>
                </li>
                <li class="create">
                  <erp-button
                      buttonDiv="SAVE"
                      :isIconCustom="true"
                      @click.native="reservationTimeCreatePopup"
                  >
                    타임생성
                  </erp-button>
                </li>
                <li class="copy">
                  <erp-button
                      buttonDiv="SAVE"
                      :isIconCustom="true"
                      @click.native="reservationTimeCopyPopup"
                  >
                    타임 복사
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                      buttonDiv="SAVE"
                      :isIconCustom="true"
                      @click.native="blankTimeSettingPopup"
                  >
                    숨김타임
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                      buttonDiv="SAVE"
                      :isIconCustom="true"
                      @click.native="blockTimeSettingPopup('BLOCK')"
                  >
                    블럭타임
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                      buttonDiv="SAVE"
                      :isIconCustom="true"
                      @click.native="blockTimeSettingPopup('HOLD')"
                  >
                    홀딩타임
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                      buttonDiv="SAVE"
                      :isIconCustom="true"
                      @click.native="selfTimeSettingPopup"
                  >
                    셀프타임
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                      buttonDiv="SAVE"
                      :isIconCustom="true"
                      @click.native="joinTimeSettingPopup"
                  >
                    조인타임
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                      buttonDiv="SAVE"
                      :isIconCustom="true"
                      @click.native="webTimeSettingPopup"
                  >
                    웹타임
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                      buttonDiv="SAVE"
                      :isIconCustom="true"
                      @click.native="realtimeWaitPopupOpen"
                  >
                    웹오픈일자
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                      buttonDiv="GET"
                      :isIconCustom="true"
                      @click.native="onDeletePromotionButtonClick"
                  >
                    프로모션삭제
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                      buttonDiv="GET"
                      :isIconCustom="true"
                      @click.native="onViewDailyReservation"
                  >
                    일자별 예약 현황
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      buttonDiv="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">멀티 편집모드</div>
                <div class="switch">
                  <ejs-switch
                    v-model="isModifyMode"
                    :checked="isModifyMode"
                    @change="onModifySwitchChanged"
                  />
                </div>
              </div>
              <div class="header-switch fixed">
                <div class="title">고정</div>
                <div class="switch">
                  <ejs-switch
                      v-model="isReservationDetailViewFixed"
                      :checked="isReservationDetailViewFixed"
                  ></ejs-switch>
                </div>
              </div>
              <div class="header-switch fixed" style="right: 90px">
                <div class="title">그린피보기</div>
                <div class="switch">
                  <ejs-switch
                      v-model="timeTableOption.isPriceView"
                      :checked="timeTableOption.isPriceView"
                      @change="onViewButtonClicked"
                  ></ejs-switch>
                </div>
              </div>
            </div>
          </div>
          <div
              class="section-body"
          >
            <!--       위에것을 지움       :class="courseSectionBodyClassName"-->
            <div
                :ref="`courseCode${courseCode.comCode}`"
                class="body-box"
                v-for="(courseCode, courseIdx) in courseCodes"
                :key="courseCode.comCode"
                :id="courseIdx"
                :style="courseGridByReservationStyle() ? widthBigBox : null"
                :class="[
                  'flexbox',
                  courseGridByReservationActive(courseCode.comCode)
                  // courseIdx === courseActiveIndex
                    ? $t('className.reservation.reservationActive')
                    : '',
                    !courseCodeFilter.includes(courseCode.comCode) && 'hideGrid'
                ]"
                @click="courseDivClicked(courseIdx)"
            >
              <div class="body-header">
                {{ courseCode.comName }}
              </div>
              <ejs-grid-wrapper
                ref="timeTableGrid"
                :columns="timeTableGridColumns"
                :provides="gridProvides"
                :dataSource="timeTableList[courseIdx] ? timeTableList[courseIdx] : []"
                :editSettings="timeTableGridEditSettings"
                :selectionSettings="timeTableGridSelectionSettings"
                :allowPaging="true"
                :allowExcelExport="true"
                :allowFiltering="!isModifyMode"
                :allowSorting="!isModifyMode"
                :pageSettings="timeTablePageSettings"
                :is-auto-select-cell="!isModifyMode"
                :validationModification="false"
                @queryCellInfo="timeTableQueryCellInfo"
                @headerCellInfo="timeTableHeaderCellInfo($event, courseIdx)"
                @actionBegin="onActionBegin($event, courseIdx)"
                @actionComplete="actionCompleteEvent($event, courseIdx)"
                @dataBound="onTimeTableGridDataBound($event, courseIdx)"
                @recordClick="onTimeTableGridClick($event, courseIdx)"
                @cellSelected="onTimeTableGridCellSelected($event, courseIdx)"
                @gridCheckboxChanged="timeTableGridCheckboxChanged($event, courseIdx)"
                @headerSelectAllCheckboxClicked="onHeaderSelectAllCheckboxClicked($event, courseIdx)"
              />
              <div class="body-footer">
                <ul>
                  <li>
                    <strong>전체</strong>
                    {{
                      timeTableList[courseIdx] ? timeTableList[courseIdx].length : 0
                    }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="387"
      :animationSettings="{ effect: 'None' }"
      :isModal="false"
      :visible="false"
      enableResize="true"
      v-if="isSearchDetailPopup"
    >
      <div class="window lookupDetail-productCodeRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">영업구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="bsnCodeDropdown"
                        v-model="timeTableOption.bsnCode"
                        :dataSource="timeTableOption.bsnCodeOptions"
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">부구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="partDivDropdown"
                        v-model="timeTableOption.partDiv"
                        :dataSource="timeTableOption.partDivOptions"
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">시간</div>
                  <ul class="content">
                    <li class="item time">
                      <input-time
                        ref="resveTimeFrom"
                        v-model="timeTableOption.resveTimeFrom"
                        format="HH:mm"
                      />
                    </li>
                    <li class="item dash">~</li>
                    <li class="item time">
                      <input-time
                        ref="resveTimeTo"
                        v-model="timeTableOption.resveTimeTo"
                        format="HH:mm"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">코스</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="resveCourseDropdown"
                        v-model="timeTableOption.resveCourse"
                        :dataSource="timeTableOption.resveCourseOptions"
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">타임상태</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="timeStatusDropdown"
                        v-model="timeTableOption.timeStatus"
                        :dataSource="timeTableOption.timeStatusOptions"
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  buttonDiv="GET"
                  :isShortcutButton="true"
                  @click.native="onDialogTimeTableOptionViewClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  buttonDiv="GET"
                  @click.native="onDialogTimeTableOptionInitClicked"
                  :isIconCustom="true"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="searchDetailClose"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <realtime-wait-reservation-popup
      v-if="isRealtimeWaitPopupOpen"
      ref="realtimeWaitPopup"
      @popupClosed="onRealtimeWaitPopupClosed"
    />
    <reservation-time-copy-popup
      v-if="isReservationTimeCopyPopupOpen"
      ref="reservationTimeCopyPopup"
      @popupClosed="onReservationTimeCopyPopupClose"
    />
    <reservationTimeCreatePopup
      v-if="isReservationTimeCreatePopupOpen"
      :filterCourse="courseCodeFilter"
      :courseDiv="timeTableOption.courseDiv"
      ref="reservationTimeCreatePopup"
      @popupClosed="onReservationTimeCreatePopupClose"
    />
    <promotionSearchPopup
      v-if="isPromotionSearchPopupOpen"
      ref="promotionSearchPopup"
      @popupClosed="onPromotionSearchPopupClose"
    />
    <blankTimeSettingPopup
      v-if="isBlankTimeSettingPopupOpen"
      :propsData="widthBox"
      :filterCourse="courseCodeFilter"
      ref="blankTimeSettingPopup"
      @popupClosed="onBlankTimeSettingPopupClose"
    />
    <blockTimeSettingPopup
      v-if="isBlockTimeSettingPopupOpen"
      :propsData="widthBox"
      :filterCourse="courseCodeFilter"
      ref="blockTimeSettingPopup"
      @popupClosed="onBlockTimeSettingPopupClose"
    />
    <selfTimeSettingPopup
      v-if="isSelfTimeSettingPopupOpen"
      :propsData="widthBox"
      :filterCourse="courseCodeFilter"
      ref="selfTimeSettingPopup"
      @popupClosed="onSelfTimeSettingPopupClose"
    />
    <joinTimeSettingPopup
      v-if="isJoinTimeSettingPopupOpen"
      :propsData="widthBox"
      :filterCourse="courseCodeFilter"
      ref="joinTimeSettingPopup"
      @popupClosed="onJoinTimeSettingPopupClose"
    />
    <timeAdditionalInformationPopup
      v-if="isTimeAdditionalInformationPopupOpen"
      ref="timeAdditionalInformationPopup"
      @popupClosed="onTimeAdditionalInformationPopupClose"
    />
    <webTimeSettingPopup
      v-if="isWebTimeSettingPopupOpen"
      ref="webTimeSettingPopup"
      :filterCourse="courseCodeFilter"
      @popupClosed="onWebTimeSettingPopupClose"
    />
    <promotion-deletion-popup
      v-if="isPromotionDeletionPopupOpen"
      ref="promotionDeletionPopup"
      @close="onPromotionPopupClose"
    />
    <daily-reservation-popup
      v-if="isDailyReservationPopupOpen"
      ref="dailyReservationPopup"
      @popupClosed="onDailyReservationPopupClose"
    />
    <edit-multiple-columns-popup
      v-if="isEditMultipleColumnsPopupOpen"
      ref="editMultipleColumnsPopup"
      @popupConfirm="onEditMultipleColumnsPopupConfirm"
      @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .body-article .article-section {height: auto;}
body .appContent .body-article .body-article .section-body {overflow: visible;border: none;}
body .appContent .article-section.section-01 .header-right {float: left;width: 100%;}
body .appContent .article-section.section-01 .section-body {overflow: hidden;border: none;}

body .appContent .article-section.section-01 .body-box {width: 229px;}
@media (min-width: 1920px) {
  body .appContent .article-section.section-01 .body-box {width: 250px;}
}
body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .body-box {width: 164px;}
@media (min-width: 1920px) {
  body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .body-box {width: 229px;}
}

body .appContent .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 229px;}
@media (min-width: 1920px) {
  body .appContent .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 250px;}
}
body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 164px;}
@media (min-width: 1920px) {
  body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 229px;}
}

body .appContent .article-section.section-01 .section-body.dev-reservation-course-num-3 .body-box {width: 369px;}
@media (min-width: 1920px) {
  body .appContent .article-section.section-01 .section-body.dev-reservation-course-num-3 .body-box {width: 369px;}
}
body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-3 .body-box {width: 229px;}
@media (min-width: 1920px) {
  body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-3 .body-box {width: 229px;}
}

body .appContent .article-section.section-01 .section-body.dev-reservation-course-num-2 .body-box {width: 426px;}
@media (min-width: 1920px) {
  body .appContent .article-section.section-01 .section-body.dev-reservation-course-num-2 .body-box {width: 746px;}
}
body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-2 .body-box {width: 229px;}
@media (min-width: 1920px) {
  body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-2 .body-box {width: 237px;}
}

/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01 .section-body .body-box,*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-active {flex: none; width: calc(100% + 2px) !important;}*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box,*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box.dev-reservation-active {flex: none; width: calc((100% / 4) + 2px) !important;}*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-3 .body-box,*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-3 .body-box.dev-reservation-active {flex: none; width: calc((100% / 3) + 2px) !important;}*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-2 .body-box,*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-2 .body-box.dev-reservation-active {flex: none; width: calc((100% / 2) + 2px) !important;}*/
body .appContent .article-section.section-01 .header-switch.fixed {top: calc(17px + 21px + 7px);}
body .appContent .article-section.section-01 .section-body {display: flex; flex-direction: row; overflow: hidden; padding: 0 1px; border: none;}
body .appContent .article-section.section-01 .section-body:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-01 .body-box {overflow: hidden; float: left; height: 100%; margin: 0 -1px;}
body .appContent .article-section.section-01 .body-grid {height: calc(100% - 54px);}
body .appContent .article-section.section-01 .body-grid >>> .e-grid.e-lib {border-bottom-color: #e0e0e0;}
body .appContent .article-section.section-01 .body-grid >>> .e-grid.e-lib .e-gridheader {border-top-color: #e0e0e0;}
body .appContent .article-section.section-01 .body-header {box-sizing: border-box; height: 24px; padding: 3px 8px 2px 8px; border: 1px solid #ccc; border-bottom: none; background-color: #f9f9f9; color: #000; text-align: center;}
body .appContent .article-section.section-01 .body-footer {overflow: hidden; box-sizing: border-box; height: 30px; padding: 0 8px; border: 1px solid #ccc; border-top: none; background-color: #f9f9f9; color: #000;}
body .appContent .article-section.section-01 .body-footer ul {display: block; list-style: none; width: 2000px; padding: 0; margin: 3px -8px;}
@supports (display: flow-root) {body .appContent .article-section.section-01 .body-footer ul {display: flow-root;}}
body .appContent .article-section.section-01 .body-footer ul:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-01 .body-footer ul li {float: left; padding: 3px 9px 3px 10px; margin: 0 0 -1px 0; background: transparent url('../../assets/images/common/division.png') no-repeat left center;}
body .appContent .article-section.section-01 .body-footer ul li strong {font-weight: bold;}
body .appContent .article-section.section-01 .body-footer ul li:first-child {padding: 3px 9px; background: none;}
body .appContent .article-section.section-01 .body-box.dev-reservation-active {flex: 1; width: auto !important; z-index: 10;}
body .appContent .article-section.section-01 .body-box.dev-reservation-active .body-grid >>> .e-grid.e-lib {border-left-color: #000; border-right-color: #000;}
body .appContent .article-section.section-01 .body-box.dev-reservation-active .body-grid >>> .dev-reservation-add-mode-row .e-rowcell:first-child {background-image: url('../../assets/images/common/focus-strong.png'); background-repeat: no-repeat; background-position: left top;}
body .appContent .article-section.section-01 .body-box.dev-reservation-active .body-header,
body .appContent .article-section.section-01 .body-box.dev-reservation-active .body-footer {border-color: #000;}
.flexbox{flex: 0.33;}
.body-grid >>> .e-gridcontent { height: calc( 100% - 67px ) !important; }
.hideGrid { visibility: hidden; position: absolute; }
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import _ from 'lodash';
import moment from 'moment';
import {
  Edit,
  VirtualScroll,
  Resize,
  ForeignKey,
  Freeze,
  Filter,
  Page,
  ExcelExport,
} from '@syncfusion/ej2-vue-grids';
import EjsGridWrapper from '@/components/common/EjsGridWrapper';
import {openNewWindow} from '@/utils/appInfo';
import {
  getFormattedDate,
  getDayColorValue,
} from '@/utils/date';
import {numberWithCommas} from '@/utils/number';
import {
  commonCodesGetCommonCode,
  commonCodesGetColorValue,
  commonCodesGetComName, commonCodesGetCommonCodeByIdx,
} from '@/utils/commonCodes';
import {getPaymentPrice} from '@/api/common';
import {
  getTimeTable,
  putTimeTable,
} from '@/api/timeTable';
import InputTime from '@/components/common/datetime/InputTime';
import InputDateRange from '@/components/common/datetime/InputDateRange';
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import reservationTimeCopyPopup from './popup/ReservationTimeCopyPopup';
import reservationTimeCreatePopup from './popup/ReservationTimeCreatePopup';
import realtimeWaitReservationPopup from './popup/RealtimeWaitReservationPopup';
import promotionSearchPopup from './popup/PromotionSearchPopup';
import blankTimeSettingPopup from './popup/BlankTimeSettingPopup';
import blockTimeSettingPopup from './popup/BlockTimeSettingPopup';
import selfTimeSettingPopup from './popup/SelfTimeSettingPopup';
import joinTimeSettingPopup from './popup/JoinTimeSettingPopup';
import timeAdditionalInformationPopup from './popup/TimeAdditionalInformationPopup';
import webTimeSettingPopup from './popup/WebTimeSettingPopup';
import PromotionDeletionPopup from '@/views/checkin/popup/PromotionDeletionPopup';
import DailyReservationPopup from '@/views/golf-reservation/popup/DailyReservationPopup';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import {deepDiffs} from "@/utils/ObjectUtil";
// import {reservationCourseGridSectionBodyClassName} from "@/utils/gridUtil";

export default {
  name: 'timeTableCreate',
  components: {
    EjsGridWrapper,
    InputTime,
    InputDateRange,
    DailyReservationPopup,
    editMultipleColumnsPopup,
    PromotionDeletionPopup,
    reservationTimeCopyPopup,
    reservationTimeCreatePopup,
    realtimeWaitReservationPopup,
    promotionSearchPopup,
    blankTimeSettingPopup,
    blockTimeSettingPopup,
    selfTimeSettingPopup,
    joinTimeSettingPopup,
    timeAdditionalInformationPopup,
    webTimeSettingPopup,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  data() {
    return {
      isReservationDetailViewFixed: true,
      timeIdList: [],
      courseActiveIndex: 0,
      courseCodes: [],
      selectedTimeData: null,
      count: 0,
      isFirstRowSelect: true,
      timeTableList: [],
      searchConditions: {
        bsnYear: null,
        bsnMt: null,
        bsnYearOptions: [],
      },
      timeTableOption: {
        isPriceView: true,
        resveDateFrom: null,
        resveDateTo: null,
        resveDateFromDay: null,
        resveDateToDay: null,
        bsnCode: null,
        bsnCodeOptions: [],
        partDiv: null,
        partDivOptions: [],
        resveTimeFrom: null,
        resveTimeTo: null,
        resveCourse: null,
        resveCourseOptions: [],
        timeStatus: null,
        timeStatusOptions: [],
        isWebTimeView: false,
        isAgencyTimeView: false,
        isSelfTimeView: false,
        isJoinTimeView: false,
        courseDiv: "%",
        courseDivOptions: null,
      },
      timeTableGridEditSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: 'Batch',
        showConfirmDialog: false,
      },
      timeTablePageSettings: {pageSize: 100},
      timeTableGridSelectionSettings: { type: "Single", mode: "Both", enableToggle: false },
      isSearchDetailPopup: false,
      isReservationTimeCopyPopupOpen: false,
      isReservationTimeCreatePopupOpen: false,
      isPromotionSearchPopupOpen: false,
      isRealtimeWaitPopupOpen: false,
      isBlankTimeSettingPopupOpen: false,
      isBlockTimeSettingPopupOpen: false,
      isSelfTimeSettingPopupOpen: false,
      isJoinTimeSettingPopupOpen: false,
      isTimeAdditionalInformationPopupOpen: false,
      isWebTimeSettingPopupOpen: false,
      isPromotionDeletionPopupOpen: false,
      isDailyReservationPopupOpen: false,
      isEditMultipleColumnsPopupOpen: false,
      resveStatusColumns: [
        {
          field: 'totalTimeCnt',
          allowEditing: false,
          type: 'number',
          headerText: '전체',
          minWidth: '16',
          width: '41',
          isNumericType: true,
          textAlign: 'Center',
        },
        {
          field: 'resveTimeCnt',
          allowEditing: false,
          type: 'number',
          headerText: '예약',
          minWidth: '16',
          width: '41',
          isNumericType: true,
          textAlign: 'Center',
        },
        {
          field: 'remainTimeCnt',
          allowEditing: false,
          type: 'number',
          headerText: '잔여',
          minWidth: '16',
          width: '41',
          isNumericType: true,
          textAlign: 'Center',
        },
      ],
      timeStatusColumns: [
        {
          field: 'blockTimeCnt',
          allowEditing: false,
          type: 'number',
          headerText: '블럭',
          minWidth: '16',
          width: '41',
          isNumericType: true,
          textAlign: 'Center',
        },
        {
          field: 'webTimeCnt',
          allowEditing: false,
          type: 'number',
          headerText: '웹',
          minWidth: '16',
          width: '41',
          isNumericType: true,
          textAlign: 'Center',
        },
        {
          field: 'agncyTimeCnt',
          allowEditing: false,
          type: 'number',
          headerText: '대행',
          minWidth: '16',
          width: '41',
          isNumericType: true,
          textAlign: 'Center',
        },
        {
          field: 'selfTimeCnt',
          allowEditing: false,
          type: 'number',
          headerText: '셀프',
          minWidth: '16',
          width: '41',
          isNumericType: true,
          textAlign: 'Center',
        },
        {
          field: 'joinTimeCnt',
          allowEditing: false,
          type: 'number',
          headerText: '조인',
          minWidth: '16',
          width: '41',
          isNumericType: true,
          textAlign: 'Center',
        },
      ],
      gridProvides: [
        Edit,
        VirtualScroll,
        Resize,
        ForeignKey,
        Freeze,
        Filter,
        Page,
        ExcelExport,
      ],
      isModifyMode: false, // 편집모드
      commonCodeFields: { text: "comName", value: "comCode" },
    };
  },
  computed: {
    // courseSectionBodyClassName() {
    //   return reservationCourseGridSectionBodyClassName(
    //       this.courseCodes,
    //       this.$t("className.reservation.reservationCourseNumPrefix")
    //   );
    // },
    courseCodeFilter() {
      let courseCodeIdx2 = [];
      commonCodesGetCommonCodeByIdx("COURSE_CODE", 2).map(item => {
        item.comCodeAttrbList.map(attrb => {
          courseCodeIdx2.push(attrb);
        });
      });
      return this.timeTableOption.courseDiv === "%" ?
        courseCodeIdx2.map(item => item.comCode) :
        courseCodeIdx2.filter(item => item.attrb === this.timeTableOption.courseDiv).map(item => item.comCode);
    },
    widthBigBox(){
      return `flex: 1;` ;
    },
    widthBox(){ //코스 갯수에 따라 width 값 자동으로 사이즈 조절
      return `width:calc((100%/${this.courseCodeFilter.length}) + 2px) !important`;
    },
    isPopupOpened() {
      return (
          this.isSearchDetailPopup ||
          this.isReservationTimeCopyPopupOpen ||
          this.isReservationTimeCreatePopupOpen ||
          this.isPromotionSearchPopupOpen ||
          this.isRealtimeWaitPopupOpen ||
          this.isBlankTimeSettingPopupOpen ||
          this.isBlockTimeSettingPopupOpen ||
          this.isSelfTimeSettingPopupOpen ||
          this.isJoinTimeSettingPopupOpen ||
          this.isTimeAdditionalInformationPopupOpen ||
          this.isWebTimeSettingPopupOpen ||
          this.isPromotionDeletionPopupOpen ||
          this.isDailyReservationPopupOpen ||
          this.isEditMultipleColumnsPopupOpen
      );
    },
    shortcutMenuItems() {
      return [
        {
          id: 1,
          text: '캘린더 등록',
        },
        {
          id: 2,
          text: '프로모션 등록',
        },
      ];
    },
    etcMenuItems() {
      return [
        {
          text: '다운로드',
        },
        {
          text: '인쇄하기',
        },
      ];
    },
    timeTableGridColumns() {
      let val = "";
      const filterStyle =
          {
            ui:{
              write:(args)=>{
                (args.filteredValue)? val = args.filteredValue : val = "";
                args.column.parent.element.lastChild.firstChild.firstChild.childNodes[1].lastChild.childNodes[1].value = val;
                args.column.parent.element.lastChild.value = args.filteredValue;
                if(this.isReservationDetailViewFixed && args.parent.element.clientWidth < 500){
                    args.column.parent.element.lastChild.style="max-height: 350px;z-index: 100001;width: 250px;top: 20px;right:0 !important";
                }
              },
            }
          };
      return [
        // {type: 'checkbox', allowEditing: false, minWidth: 16, width: 40, textAlign: 'center'},
        {
          field: 'timeId',
          allowEditing: false,
          type: 'number',
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: "select",
          headerText: "",
          allowEditing: true,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          minWidth: 16,
          width: 40,
          textAlign: "center",
          isSelectAllColumn: true,
        },
        {
          field: 'resveDate',
          allowEditing: false,
          type: 'string',
          headerText: '일자',
          minWidth: 16,
          width: 80,
          textAlign: 'center',
          isDateType: true,
          filter:filterStyle
        },
        {
          field: 'resveTime',
          allowEditing: false,
          type: 'string',
          headerText: '시간',
          minWidth: 16,
          width: 60,
          textAlign: 'center',
          isTimeType: true,
          filter:filterStyle
        },
        {
          field: 'timeStatus',
          allowEditing: false,
          type: 'string',
          headerText: '타임상태',
          minWidth: 16,
          width: 105,
          textAlign: 'left',
          editType: 'dropdownedit',
          isCommonCodeField: true,
          groupCode: 'TIME_STATUS',
          filter:filterStyle
        },
        {
          field: 'noPrice',
          allowEditing: false,
          type: 'number',
          format: 'N',
          headerText: '정상가',
          minWidth: 16,
          width: 90,
          textAlign: 'right',
          filter:filterStyle
        },
        {
          field: 'dcPrice',
          allowEditing: false,
          type: 'number',
          format: 'N',
          headerText: '할인가',
          minWidth: 16,
          width: 90,
          textAlign: 'right',
          filter:filterStyle
        },
        {
          field: 'dwName',
          allowEditing: false,
          type: 'string',
          headerText: '요일',
          minWidth: 16,
          width: 60,
          textAlign: 'center',
          filter:filterStyle
        },
        {
          field: 'partDiv',
          allowEditing: true,
          type: 'string',
          headerText: '부',
          minWidth: 16,
          width: 60,
          textAlign: 'center',
          editType: 'dropdownedit',
          isCommonCodeField: true,
          groupCode: 'PART_DIV',
          filter:filterStyle
        },
        {
          field: 'resveCourse',
          allowEditing: false,
          type: 'string',
          headerText: '코스',
          minWidth: 16,
          width: 85,
          textAlign: 'left',
          editType: 'dropdownedit',
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
          visible: false,
          filter:filterStyle
        },
        {
          field: 'holeDiv',
          allowEditing: true,
          type: 'string',
          headerText: '홀',
          minWidth: 16,
          width: 60,
          textAlign: 'left',
          editType: 'dropdownedit',
          isCommonCodeField: true,
          groupCode: 'HOLE_DIV',
          filter:filterStyle
        },
        {
          field: 'timeDiv',
          allowEditing: true,
          type: 'string',
          headerText: 'T구분',
          minWidth: 16,
          width: 70,
          textAlign: 'left',
          editType: 'dropdownedit',
          isCommonCodeField: true,
          groupCode: 'TIME_DIV',
          filter:filterStyle
        },
        {
          field: 'selfOpenFlag',
          allowEditing: false,
          type: 'boolean',
          headerText: 'S',
          minWidth: 16,
          width: 50,
          textAlign: 'Center',
          editType: 'booleanedit',
          displayAsCheckBox: true,
          filter:filterStyle
        },
        {
          field: 'webOpenFlag',
          allowEditing: false,
          type: 'boolean',
          headerText: 'W',
          minWidth: 16,
          width: 50,
          textAlign: 'Center',
          editType: 'booleanedit',
          displayAsCheckBox: true,
          filter:filterStyle
        },
        {
          field: 'visitCnt',
          allowEditing: true,
          isNumericType: true,
          inputNumberProperty: { maxLength: 1 },
          type: 'number',
          headerText: '내장인원수',
          minWidth: 16,
          width: 90,
          textAlign: 'right',
          multiEdit: {
            min: 1,
            max: 4,
            propMaxLength: 1,
            allowDot: false,
            allowMinus: false,
            displayComma: false,
          },
          filter:filterStyle
        },
        {
          field: 'resveRtrvlMethod',
          allowEditing: true,
          type: 'string',
          headerText: '타임회수',
          minWidth: 16,
          width: 90,
          textAlign: 'left',
          editType: 'dropdownedit',
          isCommonCodeField: true,
          groupCode: 'RESVE_RTRVL_METHOD',
          filter:filterStyle
        },
        {
          field: 'webOpenDt',
          allowEditing: false,
          type: 'string',
          headerText: '웹 오픈일시',
          minWidth: 16,
          width: 130,
          textAlign: 'left',
          filter:filterStyle
        },
        {
          field: 'webEndDt',
          allowEditing: false,
          type: 'string',
          headerText: '웹 마감일시',
          minWidth: 16,
          width: 130,
          textAlign: 'left',
          filter:filterStyle
        },
        {
          field: 'promtnId',
          allowEditing: true,
          type: 'string',
          headerText: '프로모션 ID',
          minWidth: 16,
          width: 120,
          textAlign: 'left',
          visible: false,
          filter:filterStyle
        },
        {
          field: 'promtnName',
          allowEditing: false,
          type: 'string',
          headerText: '프로모션',
          minWidth: 16,
          width: 150,
          textAlign: 'left',
          valueAccessor(field, data) {
            if (data['promtnId']) {
              return data[field];
            } else {
              return '';
            }
          },
          filter:filterStyle
        },
        {
          field: 'promtnDeleteIcon',
          allowEditing: false,
          type: 'string',
          headerText: '',
          minWidth: 16,
          width: 24,
          textAlign: 'left',
        },
        {
          field: 'promtnIdIcon',
          allowEditing: false,
          type: 'string',
          headerText: '',
          minWidth: 16,
          width: 24,
          textAlign: 'left',
        },
        {
          field: 'promtnPrice',
          allowEditing: false,
          type: 'number',
          format: 'N',
          headerText: '프로모션',
          minWidth: 16,
          width: 90,
          textAlign: 'right',
          filter:filterStyle
        },
        {
          field: 'eventPrice',
          allowEditing: false,
          type: 'number',
          format: 'N',
          headerText: '이벤트',
          minWidth: 16,
          width: 90,
          textAlign: 'right',
          filter:filterStyle
        },
        {
          field: 'agncyOpenFlag',
          allowEditing: false,
          type: 'boolean',
          headerText: 'A',
          minWidth: 16,
          width: 50,
          textAlign: 'Center',
          editType: 'booleanedit',
          displayAsCheckBox: true,
          filter:filterStyle
        },
        {
          field: 'joinOpenFlag',
          allowEditing: false,
          type: 'boolean',
          headerText: 'J',
          minWidth: 16,
          width: 50,
          textAlign: 'Center',
          editType: 'booleanedit',
          displayAsCheckBox: true,
          filter:filterStyle
        },
      ];
    },
    reservationDateRange: {
      get() {
        return {
          from: this.timeTableOption.resveDateFrom,
          to: this.timeTableOption.resveDateTo,
        };
      },
      set: function(reservationDateRange) {
        this.timeTableOption.resveDateFrom = reservationDateRange.from;
        this.timeTableOption.resveDateTo = reservationDateRange.to;
      },
    },
  },
  created() {
    this.courseCodes = commonCodesGetCommonCode("COURSE_CODE", true);

    this.$EventBus.$on('promotionSearchPopupOpen', () => {
      this.promotionSearchPopupOpen();
    });
    this.timeTableOption.resveDateFrom = getFormattedDate(new Date());
    this.timeTableOption.resveDateTo = getFormattedDate(new Date());
    this.timeTableOption.resveDateFromDay = commonCodesGetComName(
      'DW_CODE',
      String(new Date().getDay() + 1),
    );
    this.timeTableOption.resveDateToDay = commonCodesGetComName(
      'DW_CODE',
      String(new Date().getDay() + 1),
    );

    this.timeTableOption.bsnCodeOptions = _.orderBy(
      commonCodesGetCommonCode('BSN_CODE', true)?.filter(
        ({comCode}) => comCode !== 'CLOSE',
      ) || [],
      'sortNo',
    );
    if (
      this.timeTableOption.bsnCodeOptions.findIndex(
        (obj) => obj.comCode === 'ALL',
      ) === -1
    ) {
      this.timeTableOption.bsnCodeOptions.unshift({
        comCode: 'ALL',
        comName: '전체',
        codeAbrv: '',
        defaultFlag: false,
      });
    }

    this.timeTableOption.bsnCode = 'ALL';

    this.timeTableOption.partDivOptions = commonCodesGetCommonCode(
      'PART_DIV',
      true,
    );
    if (
      this.timeTableOption.partDivOptions.findIndex(
        (obj) => obj.comCode === 'ALL',
      ) === -1
    ) {
      this.timeTableOption.partDivOptions.unshift({
        comCode: 'ALL',
        comName: '전체',
        codeAbrv: '',
        defaultFlag: false,
      });
    }

    this.timeTableOption.partDiv = 'ALL';

    // this.timeTableOption.resveTimeFrom = '00:00'
    // this.timeTableOption.resveTimeTo = '23:59'

    this.timeTableOption.resveCourseOptions = commonCodesGetCommonCode(
      'COURSE_CODE',
      true,
    );
    if (
      this.timeTableOption.resveCourseOptions.findIndex(
        (obj) => obj.comCode === 'ALL',
      ) === -1
    ) {
      this.timeTableOption.resveCourseOptions.unshift({
        comCode: 'ALL',
        comName: '전체',
        codeAbrv: '',
        defaultFlag: false,
      });
    }

    this.timeTableOption.resveCourse = 'ALL';

    this.timeTableOption.timeStatusOptions = commonCodesGetCommonCode(
      'TIME_STATUS',
      true,
    );
    if (
      this.timeTableOption.timeStatusOptions.findIndex(
        (obj) => obj.comCode === 'ALL',
      ) === -1
    ) {
      this.timeTableOption.timeStatusOptions.unshift({
        comCode: 'ALL',
        comName: '전체',
        codeAbrv: '',
        defaultFlag: false,
      });
    }

    this.timeTableOption.timeStatus = 'ALL';

    this.timeTableOption.courseDivOptions = commonCodesGetCommonCode("COURSE_DIV", true);
    this.timeTableOption.courseDivOptions.unshift({ comCode: "%", comName: "전체" });

    this.onViewButtonClicked();

    //스크롤 이벤트
    this.$nextTick(() =>{
      this.courseCodes.forEach((course, idx) => {
        const div = document.getElementById(`${idx}`);
        if (div) {
          div.removeEventListener("scroll", this.onScl, true);
          div.addEventListener("scroll", this.onScl, true);
        }
      });
    });
  },
  mounted() {},
  beforeDestroy() {
    this.$EventBus.$off('promotionSearchPopupOpen');
  },
  methods: {
    numberWithCommas,
    commonCodesGetColorValue,
    setPromotionInfos: async function(promotionInfo, timeInfoIdx) {
      if (!this.timeTableList[this.courseActiveIndex][timeInfoIdx]) {
        return;
      }

      if (this.selectedTimeData) {
        await getPaymentPrice(
          this.selectedTimeData.resveDate,
          this.selectedTimeData.resveTime,
          this.selectedTimeData.holeDiv,
          '%',
          '%',
          promotionInfo.promtnId,
          false,
        )
          .then((response) => {
            this.$refs.timeTableGrid[this.courseActiveIndex].updateCell(
              timeInfoIdx,
              'promtnPrice',
              response.value.promtnPrice,
            );
          })
          .catch((error) => {
            console.log('getPaymentPrice.err.===>', error);
          });
      }

      this.$refs.timeTableGrid[this.courseActiveIndex].updateCell(
        timeInfoIdx,
        'promtnId',
        promotionInfo.promtnId,
      );
      this.$refs.timeTableGrid[this.courseActiveIndex].updateCell(
        timeInfoIdx,
        'promtnName',
        promotionInfo.promtnName,
      );
    },
    clearPromotionInfos: function(timeInfoIdx) {
      if (this.timeTableList[this.courseActiveIndex][timeInfoIdx]) {
        this.timeTableList[this.courseActiveIndex][timeInfoIdx].promtnId = null;
        this.timeTableList[this.courseActiveIndex][timeInfoIdx].promtnName = null;
      }
    },
    timeTableHeaderCellInfo(args, idx) {
      const {
        cell: {
          column: {
            field,
            headerText,
          },
        },
        node,
      } = args;
      if (headerText === 'NO') {
        node.classList.add(this.$t('className.grid.noSortFilter'));
      }
      if (field === 'resveDate' && !this.isModifyMode) {
        node.classList.add(this.$t('className.grid.clickArea'));
      } else if (field === 'promtnDeleteIcon') {
        node.classList.add(this.$t('className.grid.deleteArea'));
      } else if (field === 'promtnIdIcon') {
        node.classList.add(this.$t('className.grid.searchIconArea'));
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.timeTableGridColumns
          ?.filter(item => item.allowEditing && item.field !== 'promtnId' && item.field !== "select")
          ?.map(item => item.field)
          ?.concat(['promtnName', 'promtnDeleteIcon']);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column, idx), false);
        }
      }
    },
    async onGridHeaderClicked(column, idx) {
      const gridRefs = this.$refs.timeTableGrid[idx];
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];
      if (column?.field === 'promtnName') {
        this.isPromotionSearchPopupOpen = true;
        await this.$nextTick();
        this.$refs.promotionSearchPopup.showPromotionSearchPopup({
          selectedRowIndexes,
        });
      } else if (column?.field === 'promtnDeleteIcon') {
        selectedRowIndexes.forEach(rowIndex => {
          gridRefs?.updateCell(rowIndex, 'promtnId', null);
          gridRefs?.updateCell(rowIndex, 'promtnName', null);
        });
      } else {
        this.isEditMultipleColumnsPopupOpen = true;
        await this.$nextTick();
        this.$refs.editMultipleColumnsPopup.showPopup(
          column,
          selectedRowIndexes
        );
      }
    },
    timeTableQueryCellInfo(args) {
      const {
        column: {field},
        cell,
        data,
        foreignKeyData,
      } = args;
      if (field === undefined) {
        if (data.agncyOpenFlag) {
          cell.style.backgroundColor = this.commonCodesGetColorValue(
            "COLOR_TIME_STATUS",
            "AGNCY_OPEN_FLAG"
          );
        } else if (data.webOpenFlag) {
          if (data.timeDiv === "OCLUB") {
            cell.style.backgroundColor = this.commonCodesGetColorValue(
              "COLOR_TIME_STATUS",
              "WEB_OCLUB_TIME"
            );
          } else {
            cell.style.backgroundColor = this.commonCodesGetColorValue(
              "COLOR_TIME_STATUS",
              "WEB_OPEN_FLAG"
            );
          }
        }
      } else if (field === 'resveDate') {
        // 일자
        cell.classList.add(this.$t('className.grid.clickArea'));
      } else if (field === 'timeStatus') {
        if (
          data.timeStatus === 'BLANK' ||
          data.timeStatus === 'BLOCK' ||
          data.timeStatus === 'HOLD' ||
          data.timeStatus === 'RESVE'
        ) {
          cell.style.backgroundColor = this.commonCodesGetColorValue(
            'TIME_STATUS',
            data.timeStatus,
          );
          cell.style.color = '#FFFFFF';
        }
      } else if (field === 'holeDiv') {
        if (foreignKeyData && foreignKeyData[0].codeAbrv <= 9) {
          cell.style.backgroundColor = this.commonCodesGetColorValue(
            'HOLE_DIV',
            data.holeDiv,
          );
        }
      } else if (field === 'promtnDeleteIcon') {
        cell.classList.add(this.$t('className.grid.deleteArea'));
      } else if (field === 'promtnIdIcon') {
        cell.classList.add(this.$t('className.grid.searchIconArea'));
      } else if (field === 'promtnDeleteIcon') {
        // cell.classList.add(this.$t('className.grid.searchIconArea'));
      } else if (field === 'dwName') {
        cell.style.color = getDayColorValue(data.dwCode, data.bsnCode);
      } else if (field === "select") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (
        field === 'partDiv' ||
        field === 'holeDiv' ||
        field === 'timeDiv' ||
        field === 'visitCnt' ||
        field === 'resveRtrvlMethod' ||
        (field === 'promtnName' && this.isModifyMode) ||
        cell.classList.contains(
          'e-gridchkbox',
        ) /* 체크박스는 필드명을 제외하고 이와 같이 처리함 */
      ) {
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
      if ([
        'promtnPrice',
        'eventPrice',
        'noPrice',
        'dcPrice',
      ].includes(field) && data[field] === 0) {
        cell.innerText = '-';
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.timeTableGridColumns
          ?.filter(item => item.allowEditing && item.field !== 'promtnId' && item.field !== "select")
          ?.map(item => item.field)
          ?.concat(['promtnName', 'promtnDeleteIcon']);
        if (!allowedEditColumns.includes(field)) {
          if (!(data.timeStatus !== 'EMPTY' && field === 'timeStatus')) {
            cell.style.backgroundColor = '#f9f9f9';
          }
          cell.style.pointerEvents = 'none';
        }
      }
    },
    onResveDateFromChange(args) {
      if (args.data == null) {
        this.timeTableOption.resveDateFromDay = null;
      } else {
        this.timeTableOption.resveDateFromDay = commonCodesGetComName(
          'DW_CODE',
          String(moment(args.data, 'YYYY-MM-DD').day() + 1),
        );
        if (
          moment(this.timeTableOption.resveDateFrom).isAfter(
            this.timeTableOption.resveDateTo,
          )
        ) {
          this.timeTableOption.resveDateTo = this.timeTableOption.resveDateFrom;
        }
      }
    },
    onResveDateToChange(args) {
      if (args.data == null) {
        this.timeTableOption.resveDateToDay = null;
      } else {
        this.timeTableOption.resveDateToDay = commonCodesGetComName(
          'DW_CODE',
          String(moment(args.data, 'YYYY-MM-DD').day() + 1),
        );
        if (
          moment(this.timeTableOption.resveDateFrom).isAfter(
            this.timeTableOption.resveDateTo,
          )
        ) {
          this.timeTableOption.resveDateFrom = this.timeTableOption.resveDateTo;
        }
      }
    },
    onDialogTimeTableOptionInitClicked() {
      this.timeTableOption.bsnCode = 'ALL';
      this.timeTableOption.partDiv = 'ALL';
      this.timeTableOption.resveTimeFrom = '00:00';
      this.timeTableOption.resveTimeTo = '23:59';
      this.timeTableOption.resveCourse = 'ALL';
      this.timeTableOption.timeStatus = 'ALL';
    },
    onTimeTableInitClicked() {
      for (let i = 0; i < this.courseCodes.length; i++) {
        this.$refs.timeTableGrid[i].refresh();
      }
    },
    realtimeWaitPopupOpen() {
      this.isRealtimeWaitPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.realtimeWaitPopup.showRealtimeWaitReservationPopup(
          this.timeTableOption.resveDateFrom,
          this.timeTableOption.resveDateTo,
          this.timeTableOption.bsnCode,
        );
      });
    },
    onDeletePromotionButtonClick() {
      this.isPromotionDeletionPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.promotionDeletionPopup.showPopup({
          isPriceView: this.timeTableOption.isPriceView,
          resveDateFrom: this.timeTableOption.resveDateFrom,
          resveDateTo: this.timeTableOption.resveDateTo,
          // this.timeTableOption.bsnCode
        });
      });
    },
    onViewDailyReservation() {
      const resveDate =
        (this.$refs.timeTableGrid[this.courseActiveIndex].getSelectedRecords() || [])[0]
          ?.resveDate || this.timeTableOption.resveDateFrom;

      this.isDailyReservationPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.dailyReservationPopup.showDailyReservationPopup(resveDate);
      });
    },
    reservationTimeCopyPopup() {
      this.isReservationTimeCopyPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.reservationTimeCopyPopup.showReservationTimeCopyPopup(
          this.timeTableOption.resveDateFrom,
          this.timeTableOption.resveDateTo,
          this.timeTableOption.bsnCode,
        );
      });
    },
    timePopupOpenMappingOption() {
      const timeTableOptionMapping = (field) => this.timeTableOption[field] === "ALL" ? null : this.timeTableOption[field];
      return {
        bsnCode: timeTableOptionMapping("bsnCode"),
        partDiv: timeTableOptionMapping("partDiv"),
        resveCourse: timeTableOptionMapping("resveCourse"),
        resveTimeFrom: this.timeTableOption.resveTimeFrom,
        resveTimeTo: this.timeTableOption.resveTimeTo,
        resveDateFrom: this.timeTableOption.resveDateFrom,
        resveDateTo: this.timeTableOption.resveDateTo,
      };
    },
    blankTimeSettingPopup() {
      const timeOption = this.timePopupOpenMappingOption();
      this.isBlankTimeSettingPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.blankTimeSettingPopup.showBlankTimeSettingPopup(
            'BLANK',
            timeOption
        );
      });
    },
    blockTimeSettingPopup(state) {
      // block || hold
      const timeOption = this.timePopupOpenMappingOption();
      this.isBlockTimeSettingPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.blockTimeSettingPopup.showBlockTimeSettingPopup(
          state,
          timeOption
        );
      });
    },
    selfTimeSettingPopup() {
      const timeOption = this.timePopupOpenMappingOption();
      this.isSelfTimeSettingPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.selfTimeSettingPopup.showSelfTimeSettingPopup(
            'SELF',
            timeOption
        );
      });
    },
    joinTimeSettingPopup() {
      const timeOption = this.timePopupOpenMappingOption();
      this.isJoinTimeSettingPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.joinTimeSettingPopup.showJoinTimeSettingPopup(
            "JOIN",
            timeOption
        );
      });
    },
    async webTimeSettingPopup() {
      await this.$nextTick();
      const selectedRecords = this.$refs.timeTableGrid[this.courseActiveIndex].getSelectedRecords() || [];
      const resveDateFrom = (selectedRecords)[0]?.resveDate || this.timeTableOption.resveDateFrom;
      const resveDateTo = this.timeTableOption.resveDateTo;
      const isPriceView = this.timeTableOption.isPriceView;
      this.isWebTimeSettingPopupOpen = true;
      setTimeout(() => {
        this.$refs.webTimeSettingPopup.showWebTimeSettingPopup(resveDateFrom, resveDateTo, isPriceView);
      }, 1);
    },
    reservationTimeCreatePopup() {
      this.isReservationTimeCreatePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.reservationTimeCreatePopup.showReservationTimeCreatePopup(
            this.timeTableOption.resveDateFrom,
            this.timeTableOption.resveDateTo,
            this.timeTableOption.bsnCode,
        );
      });
    },
    promotionSearchPopupOpen(isOpenedWithButton, timeInfoIdx) {
      this.isPromotionSearchPopupOpen = true;

      let promotionPopupData = {};
      if (this.timeTableList[this.courseActiveIndex][timeInfoIdx]) {
        promotionPopupData.promtnName = this.timeTableList[this.courseActiveIndex][timeInfoIdx].promtnName;
      }
      if (isOpenedWithButton) {
        promotionPopupData.isOpenedWithButton = isOpenedWithButton;
      }
      promotionPopupData.timeInfoIdx = timeInfoIdx;

      this.$nextTick(() => {
        this.$refs.promotionSearchPopup.showPromotionSearchPopup(
            promotionPopupData,
        );
      });
    },
    onRealtimeWaitPopupClosed(event) {
      this.isRealtimeWaitPopupOpen = false;
      if (event && event.isReload === true) {
        this.infoToast(this.$t('main.popupMessage.saved'));
        this.onViewButtonClicked();
      }
    },
    onReservationTimeCopyPopupClose(event) {
      this.isReservationTimeCopyPopupOpen = false;
      if (event && event.isReload === true) {
        // this.timeTableOption.resveDateFrom = event.timeTableOption.copyDateFrom;
        // this.timeTableOption.resveDateTo = event.timeTableOption.copyDateTo;
        this.onViewButtonClicked();
        // alert('타임복사 완료되었음. 조회API 호출.')
      }
    },
    onReservationTimeCreatePopupClose(event) {
      this.isReservationTimeCreatePopupOpen = false;
      if (event && event.isReload === true) {
        this.onViewButtonClicked();
        // alert('타임생성 완료되었음. 조회API 호출.')
      }
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.timeTableGrid[this.courseActiveIndex].updateCell(
          index,
          field,
          value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    onBlankTimeSettingPopupClose(event) {
      this.isBlankTimeSettingPopupOpen = false;
      if (event && event.isReload === true) {
        this.onViewButtonClicked();
        // alert('Blank 타임설정 완료되었음. 조회API 호출.')
      }
    },
    onBlockTimeSettingPopupClose(event) {
      this.isBlockTimeSettingPopupOpen = false;
      if (event && event.isReload === true) {
        this.onViewButtonClicked();
        // alert('Block 타임설정 완료되었음. 조회API 호출.')
      }
    },
    onSelfTimeSettingPopupClose(event) {
      this.isSelfTimeSettingPopupOpen = false;
      if (event && event.isReload === true) {
        this.onViewButtonClicked();
        // alert('Self 타임설정 완료되었음. 조회API 호출.')
      }
    },
    onJoinTimeSettingPopupClose(event) {
      this.isJoinTimeSettingPopupOpen = false;
      if (event && event.isReload === true) {
        this.onViewButtonClicked();
        // alert('Join 타임설정 완료되었음. 조회API 호출.')
      }
    },
    onTimeAdditionalInformationPopupClose() {
      this.isTimeAdditionalInformationPopupOpen = false;
    },
    onWebTimeSettingPopupClose(event) {
      this.isWebTimeSettingPopupOpen = false;
      if (event && event.isReload === true) {
        this.onViewButtonClicked();
        // alert('웹 타임 설정 완료되었음. 조회API 호출.')
      }
    },
    onPromotionPopupClose() {
      this.isPromotionDeletionPopupOpen = false;
      this.getTimeTable();
    },
    onDailyReservationPopupClose() {
      this.isDailyReservationPopupOpen = false;
    },
    onPromotionSearchPopupClose(event) {
      this.isPromotionSearchPopupOpen = false;
      if (this.isModifyMode) {
        event.popupData.selectedRowIndexes?.forEach(timeInfoIdx => {
          if (event.selectedPromotion) {
            this.setPromotionInfos(
              event.selectedPromotion,
              timeInfoIdx,
            );
          }
        });
      } else {
        // [프로모션 Case 분기(항목은 ReservationTimeInfo.java 참고)]
        // 프로모션 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 프로모션ID, 프로모션명 설정
        // 프로모션 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 프로모션 팝업을 선택하지 않고 닫는다) : 프로모션ID, 프로모션명 Clear
        if (event.popupData.isOpenedWithButton) {
          // 버튼으로 팝업 Open : 검색하여 선택한 경우에만 처리
          if (event.selectedPromotion) {
            this.setPromotionInfos(
              event.selectedPromotion,
              event.popupData.timeInfoIdx,
            );
          }
        } else {
          // text 창 입력으로 팝업을 Open
          if (event.selectedPromotion) {
            this.setPromotionInfos(
              event.selectedPromotion,
              event.popupData.timeInfoIdx,
            );
          } else {
            this.clearPromotionInfos(event.popupData.timeInfoIdx);
          }
        }
      }
    },
    async searchDetail() {
      if (this.timeTableOption.resveTimeFrom === null) {
        this.timeTableOption.resveTimeFrom = '00:00';
      }
      if (this.timeTableOption.resveTimeTo === null) {
        this.timeTableOption.resveTimeTo = '23:59';
      }
      this.isSearchDetailPopup = true;
      await this.$nextTick();
      this.$refs.searchDetailPopup.show();
    },
    searchDetailClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopup = true;
    },
    onViewButtonClicked() {
      if (!this.timeTableOption.resveDateFrom) {
        return this.errorToast(
            this.$t('main.validationMessage.requiredMessage', ['예약일자']),
        );
      }
      if (!this.timeTableOption.resveDateTo) {
        return this.errorToast(
            this.$t('main.validationMessage.requiredMessage', ['종료일자']),
        );
      }
      this.getTimeTable();
    },
    onSaveButtonClicked() {
      let changedRecords = [];
      for (let i = 0; i < this.courseCodes.length; i++) {
        this.$refs.timeTableGrid[i].saveCell();
        this.$refs.timeTableGrid[i].getBatchChanges().changedRecords.map(record => {
          changedRecords.push(record);
        });
        if (!this.$refs.timeTableGrid[i].validate()) {
          return;
        }
      }

      if (!(changedRecords.length > 0)) {
        this.errorToast(
            this.$t("main.validationMessage.noChanges")
        );
        return;
      }

      const hasStrangeVisitCnt = changedRecords
        ?.filter(item => item.visitCnt < 1 || item.visitCnt > 5)
        ?.length;
      if (hasStrangeVisitCnt > 0) {
        this.errorToast('내장인원수는 1부터 5까지 입력 가능합니다');
        return;
      }


      this.putTimeTable({
        addedRecords: [],
        changedRecords: changedRecords,
        deletedRecords: [],
      });
    },
    async onDeleteButtonClicked() {
      if (!(this.timeIdList.length > 0)) {
        this.errorToast("선택된 자료가 없습니다");
        return;
      }

      let data = [];
      this.timeTableList.forEach(timeTable => {
        timeTable.filter(item => this.timeIdList.includes(item.timeId))
            .map(item => {
              data.push(item);
            });
      });

      for (let i = 0; i < data.length; i++) {
        if (data[i].timeStatus !== 'EMPTY' && data[i].timeStatus !== 'BLANK') {
          this.errorToast(
              '예약가능, 숨김 Time 이외의 자료가 존재합니다. 확인하신 후 작업하시기 바랍니다',
          );
          return;
        }
      }

      console.log('data.===>', data);

      await this.confirm('선택한 타임을 삭제하시겠습니까?').then(
          (confirmed) => {
            if (confirmed) {
              const deleteData = {
                addedRecords: [],
                changedRecords: [],
                deletedRecords: data,
              };

              this.putTimeTable(deleteData);
            }
          },
      );
    },
    onDialogTimeTableOptionViewClicked() {
      this.onViewButtonClicked();
    },
    getTimeTable() {
      this.timeTableList = [];
      this.timeIdList = [];
      const bsnCode =
        this.timeTableOption.bsnCode === 'ALL'
          ? null
          : this.timeTableOption.bsnCode;
      const partDiv =
        this.timeTableOption.partDiv === 'ALL'
          ? null
          : this.timeTableOption.partDiv;
      const resveCourse =
        this.timeTableOption.resveCourse === 'ALL'
          ? null
          : this.timeTableOption.resveCourse;

      const resveDateFrom = this.timeTableOption.resveDateFrom;
      const resveDateTo = this.timeTableOption.resveDateTo;
      const resveTimeFrom = this.timeTableOption.resveTimeFrom;
      const resveTimeTo = this.timeTableOption.resveTimeTo;
      const isWebTimeView = this.timeTableOption.isWebTimeView;
      const isAgencyTimeView = this.timeTableOption.isAgencyTimeView;
      const isSelfTimeView = this.timeTableOption.isSelfTimeView;
      const isJoinTimeView = this.timeTableOption.isJoinTimeView;
      const isPriceView = this.timeTableOption.isPriceView;
      const timeStatus =
        this.timeTableOption.timeStatus === 'ALL'
          ? null
          : this.timeTableOption.timeStatus;

      getTimeTable(
        isPriceView,
        bsnCode,
        partDiv,
        resveCourse,
        resveDateFrom,
        resveDateTo,
        resveTimeFrom,
        resveTimeTo,
        timeStatus,
        null,
        isWebTimeView,
        isAgencyTimeView,
        isSelfTimeView,
        isJoinTimeView,
      )
        .then((response) => {
          this.courseCodes.forEach(courseCode => {
            this.timeTableList.push(
                response.value.timeTableList?.filter(item => !item.galleyTeamFlag && item.resveCourse === courseCode.comCode)?.map(item => {
                  item.select = false;
                  return item;
                })
            );
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    putTimeTable(data) {
      let modifyDatas = [];
      if (data?.changedRecords.length > 0) {
        let allTimeTableList = [];
        this.timeTableList.map(timeTables => {
          timeTables.map(timeTable => {
            allTimeTableList.push(timeTable);
          });
        });
        data.changedRecords.map(record => {
          const prevData = allTimeTableList.filter(time => time.timeId === record.timeId).length > 0 ? allTimeTableList.filter(time => time.timeId === record.timeId)[0] : null;
          let deepDiffsData = deepDiffs(prevData, record);
          deepDiffsData.timeId = record.timeId;
          deepDiffsData.resveDate = record.resveDate;
          deepDiffsData.optFlag = record.optFlag;
          deepDiffsData.promtnId = record.promtnId;
          modifyDatas.push(deepDiffsData);
        });
        data.changedRecords = modifyDatas;
      }

      putTimeTable(data)
        .then(() => {
          this.onViewButtonClicked();
          this.infoToast(this.$t('main.popupMessage.saved'));
        })
        .catch((error) => {
          console.error(error);
          this.$refs.timeTableGrid.forEach(gridRef => {
            gridRef.refresh();
          });
        });
    },
    onActionBegin(args, idx) {
      const {
        action,
        requestType,
        currentFilterObject,
      } = args;

      if (action === "filter" && requestType === "filtering") {
        // 입력값이 없으면 clearFiltering.
        if (!currentFilterObject.value) {
          this.$refs.timeTableGrid[idx].clearFiltering();
        }
      }
    },
    actionCompleteEvent(args, idx) {
      this.count = numberWithCommas(
          this.$refs.timeTableGrid[idx]?.getGridBatchCount() || 0,
      );
      if (args.requestType === 'refresh') {
        const selectRowIndexes = this.$refs.timeTableGrid[idx].getSelectedRowIndexes();
        if (0 <= selectRowIndexes[0]) {
          const targetIndex = selectRowIndexes[0];
          this.$refs.timeTableGrid[idx].selectRow(targetIndex);
        }
      }
    },
    onTimeTableGridDataBound(args, idx) {
      if (this.isFirstRowSelect) {
        if (this.timeTableList[idx]?.length > 0) {
          this.$refs.timeTableGrid[idx].selectRow(0);
          this.isFirstRowSelect = false;
        }
      } else {
        if (this.timeTableList[idx]?.length <= 0) {
          this.isFirstRowSelect = true;
        }
      }
    },
    onTimeTableGridClick(args, idx) {
      const {
        column: {
          field
        },
        rowData,
        rowIndex
      } = args;

      this.selectedTimeData = null;

      if (field === 'resveDate') {
        this.isTimeAdditionalInformationPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.timeAdditionalInformationPopup.getDailyTimeStatus(
              rowData.resveDate,
          );
        });
      } else if (field === "promtnIdIcon") {
        this.selectedTimeData = rowData;
        this.promotionSearchPopupOpen(true, rowIndex);
      } else if (field === "promtnDeleteIcon") {
        this.$refs.timeTableGrid[idx].updateCell(
          rowIndex,
          'promtnName',
          null,
        );
        this.$refs.timeTableGrid[idx].updateCell(
          rowIndex,
          'promtnId',
          null,
        );
      }
    },
    shortcutMenuSelected(args) {
      switch (args.item.id) {
        case 1: // 캘린더 등록 새 창
          // eslint-disable-next-line no-case-declarations
          const gridSelectedRecords = this.$refs.timeTableGrid[this.courseActiveIndex].getSelectedRecords();
          // eslint-disable-next-line no-case-declarations
          let query;

          if (gridSelectedRecords.length >= 1) {
            const resveDateMoment = moment(gridSelectedRecords[0].resveDate);
            if (resveDateMoment.isValid()) {
              query = {
                bsnYear: resveDateMoment.format('YYYY'),
                bsnMt: resveDateMoment.format('M'),
              };
            }
          }
          // eslint-disable-next-line no-case-declarations
          let calendarRouteData = this.$router.resolve({
            name: 'calendarRegistration',
            query: query,
          });
          openNewWindow(calendarRouteData);
          break;
        case 2: // 프로모션 등록 새 창
          // eslint-disable-next-line no-case-declarations
          let promotionRouteData = this.$router.resolve({
            name: 'promotionRegistration',
          });
          openNewWindow(promotionRouteData);
          break;
        default:
          break;
      }
    },
    onClickExcel() {
      this.$refs.timeTableGrid.forEach(gridRef => {
        gridRef.excelExport();
      });
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.timeTableGridSelectionSettings = isChecked
        ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
        : {mode: 'Both', type: 'Multiple'};
      this.$refs.timeTableGrid.forEach(gridRef => {
        gridRef.refresh();
      });
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async onTimeTableGridCellSelected(args, idx) {
      if (!this.isModifyMode) {
        return;
      }
      const gridRefs = this.$refs.timeTableGrid[idx];
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        gridRefs?.refresh();
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      const allowedEditColumns = this.timeTableGridColumns
        ?.filter(item => item.allowEditing && item.field !== 'promtnId')
        ?.map(item => item.field)
        ?.concat(['promtnName', 'promtnDeleteIcon']);
      const column = this.timeTableGridColumns[cellIndex - 1];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      // 프로모션
      if (column?.field === 'promtnName') {
        this.isPromotionSearchPopupOpen = true;
        await this.$nextTick();
        this.$refs.promotionSearchPopup.showPromotionSearchPopup({
          selectedRowIndexes,
        });
      } else if (column?.field === 'promtnDeleteIcon') {
        selectedRowIndexes.forEach(rowIndex => {
          gridRefs?.updateCell(rowIndex, 'promtnId', null);
          gridRefs?.updateCell(rowIndex, 'promtnName', null);
        });
      } else {
        this.isEditMultipleColumnsPopupOpen = true;
        await this.$nextTick();
        this.$refs.editMultipleColumnsPopup.showPopup(
          column,
          selectedRowIndexes
        );
      }
    },
    courseDivClicked(idx) {
      this.courseActiveIndex = idx;
    },
    courseGridByReservationStyle() {
      const resveCourseList = this.courseCodes.map((item, idx) => {
        return {
          code: item.comCode,
          idx: idx
        };
      });

      const code = resveCourseList.filter(item => item.idx === this.courseActiveIndex)[0].code;

      let courseCodeIdx2 = [];
      commonCodesGetCommonCodeByIdx("COURSE_CODE", 2).map(item => {
        item.comCodeAttrbList.map(attrb => {
          courseCodeIdx2.push(attrb);
        });
      });
      courseCodeIdx2 = this.timeTableOption.courseDiv === "%" ?
        courseCodeIdx2.map(item => item.comCode) :
        courseCodeIdx2.filter(item => item.attrb === this.timeTableOption.courseDiv).map(item => item.comCode);

      return this.isReservationDetailViewFixed || (!this.isReservationDetailViewFixed && !courseCodeIdx2.includes(code));
    },
    courseGridByReservationActive(courseCode) {
      const resveCourseList = this.courseCodes.map((item, idx) => {
        return {
          code: item.comCode,
          idx: idx
        };
      });

      const code = resveCourseList.filter(item => item.idx === this.courseActiveIndex)[0].code;

      let courseCodeIdx2 = [];
      commonCodesGetCommonCodeByIdx("COURSE_CODE", 2).map(item => {
        item.comCodeAttrbList.map(attrb => {
          courseCodeIdx2.push(attrb);
        });
      });
      courseCodeIdx2 = this.timeTableOption.courseDiv === "%" ?
        courseCodeIdx2.map(item => item.comCode) :
        courseCodeIdx2.filter(item => item.attrb === this.timeTableOption.courseDiv).map(item => item.comCode);

      return courseCodeIdx2.includes(courseCode) && courseCode === code;
    },
    timeTableGridCheckboxChanged(args, idx) {
      const {
        columnName: field,
        rowData
      } = args;
      if (field === "select") {
        const hasCaddieId = this.timeIdList
            ?.findIndex(item => item === rowData.timeId);
        const findIndex = this.timeTableList[idx]
            ?.findIndex(item => item.timeId === rowData.timeId);
        if (findIndex > -1) {
          if (hasCaddieId > -1) {
            this.timeTableList[idx][findIndex].select = false;
            delete this.timeIdList[hasCaddieId];
            this.timeIdList = this.timeIdList
                ?.filter(item => !!item);
          } else {
            this.timeTableList[idx][findIndex].select = true;
            this.timeIdList.push(rowData.timeId);
          }
          this.$forceUpdate();
        }
      }
    },
    onHeaderSelectAllCheckboxClicked(args, idx) {
      const {
        field,
        value: flag,
      } = args;
      if (field === "select") {
        const filteredRecords = this.$refs.timeTableGrid[idx].getFilteredRecords();
        for (let i = 0; i < this.timeTableList[idx].length; i++) {
          if (flag) {
            if (filteredRecords.length > 0) {
              if (filteredRecords.filter(item => item.timeId === this.timeTableList[idx][i].timeId).length > 0) {
                this.timeIdList.push(this.timeTableList[idx][i].timeId);
              }
            } else {
              this.timeIdList.push(this.timeTableList[idx][i].timeId);
            }
          } else {
            if (filteredRecords.length > 0) {
              if (filteredRecords.filter(item => item.timeId === this.timeTableList[idx][i].timeId).length > 0) {
                this.timeIdList.splice(this.timeIdList.findIndex(findData => findData === this.timeTableList[idx][i].timeId), 1);
              }
            } else {
              this.timeIdList.splice(this.timeIdList.findIndex(findData => findData === this.timeTableList[idx][i].timeId), 1);
            }
          }
        }
        this.timeTableList[idx]
            ?.map(item => {
              if (filteredRecords.length > 0) {
                if (filteredRecords.filter(record => record.timeId === item.timeId).length > 0) {
                  item.select = !!flag;
                } else {
                  item.select = !flag;
                }
              } else {
                item.select = !!flag;
              }
              return item;
            });
      }
    },
    onScl(evt) {
      //div ID 인덱스 번호 추출, courseGrid0
      const currentIdx = (evt.currentTarget.id).slice(-1);
      const top = this.$refs.timeTableGrid[currentIdx].getContent().children[0].scrollTop;

      this.$refs.timeTableGrid.forEach((gird, idx) => {
        if (idx !== Number.parseInt(currentIdx)) {
          gird.getContent().children[0].scrollTop = top;
        }
      });
    },
  },
};
</script>
